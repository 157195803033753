<template>
  <div class="row justify-content-center">
    <!-- loader -->
    <div id="loader" style="display: none">
      <!--      <img src="assets/img/loading-icon.png" alt="icon" class="loading-icon">-->
    </div>
    <!-- * loader -->

    <!-- App Header -->
    <div class="appHeader no-border transparent position-absolute">
      <div class="left">
        <a href="#" class="headerButton goBack">
          <!--          <ion-icon name="chevron-back-outline"></ion-icon>-->
        </a>
      </div>
      <div class="pageTitle"></div>
      <div class="right">
      </div>
    </div>
    <!-- * App Header -->

    <!-- App Capsule -->
    <div id="appCapsule" class="col-md-6 col-lg-4 col-xl-4">

      <div class="section mt-2 text-center">
        <h3>Kerala Government Hospital Development Society Employees Union</h3> 
      </div>
      <div class="section mb-5 p-2 ">
        <h2>Enrollment window closed !</h2>
      </div>

    </div>
    <!-- * App Capsule -->
  </div>
</template>

<script>
export default {
  name: 'Expired',
  data() {
    return {
    }
  },
  components: {
  },
  methods: {
  }
}
</script>

<style scoped>
#appCapsule {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}
img {
    width: 200px;
    height: auto;
}
</style>
